.LoginSection {
    margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	font-family: 'Jost', sans-serif;
	background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');



@keyframes animateBg {
    100% {
        filter: hue-rotate(360deg);
    }
}

.login form .inputBox input[type="submit"]:hover{
    box-shadow: 2px 2px 8px -1px white;
}

.animetion{
    z-index: 100;
    margin-right: -71px;
    height: 596px;
}
.form-box {
    position: relative;
    width: 400px;
    height: 450px;
    background-color: transparent;
    border: 2px solid rgb(66, 66, 66, 50);
    border-radius: 20px;
    backdrop-filter: blur(24px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-box .form-value .heading {
    font-size: 2rem;
    color: #333;
    text-align: center;
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.input-box {
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #333;
}

.input-box label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #333;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

input:focus~label,
input:focus~:valid {
    top: -5px;
}

.input-box input {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0px 35px 0px 5px;
    color: #333;
}

.input-box ion-icon {
    position: absolute;
    right: 8px;
    color: #333;
    font-size: 1.2em;
    top: 20px;
}

.btnTheme {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
}

.form-errors {
    margin-top: 10px;
    color: red;
}

.addbtn {
    display: flex;
    justify-content: flex-end;
}

.starcolor {
    color: red;
}

/* New Login CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.login {
    width: 350px;
	height: 500px;
	background: red;
	overflow: hidden;
	background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
    display: flex;
    align-items: center;
}

.login form {
    position: relative;
    width: 100%;
}

.login form h2 {
    font-size: 2em;
    margin-bottom: 30px;
    line-height: 0.9em;
    color: white;
}

.login form h2 span {
    font-weight: 300;
    font-size: 0.55em;
    color: white;
}

.login form .inputBox {
    position: relative;
    display: flex;
}

.login form .inputBox input {
    border: none;
    outline: none;
    background: transparent;
    border-radius: 10px;
    font-size: 1em;
}

.login form .inputBox input[type="text"],
.login form .inputBox input[type="password"] {
    width: 90%;
    padding: 10px 20px;
    padding-left: 40px;
    margin-bottom: 20px;
    /* box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
        inset -5px -5px 10px #fff; */
        /* justify-content: center; */
        /* display: flex; */
        margin: 20px auto;
        /* padding: 10px;
        padding-left: 20px; */
        border: none;
        outline: none;
        border-radius: 5px;
        background: #e0dede;

}

.login form .inputBox i {
    position: absolute;
    left: 27px;
    top: 39%;
}

.login form .inputBox input[type="submit"] {
    width: 60%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    background: #573b8a;
    font-size: 1em;
    font-weight: 500;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in;
    cursor: pointer;
}

/* .login form .inputBox input[type="submit"]:focus {
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
        inset -5px -5px 10px #fff;
} */

.login .logo {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
}

.login form .inputBox span i {
    position: absolute;
    display: flex;
    justify-content: end;
    right: 27px;
    top: 39%;
}

/* Media Query for Small Screens (up to 767px) */
@media (max-width: 767px) {
    .form-box {
        width: 80%;
    }

    .input-box {
        width: 100%;
        margin: 20px 0;
    }

    .input-box input {
        font-size: 0.9em;
    }

    .btnTheme {
        font-size: 0.9em;
    }
}


@media (min-width: 768px) and (max-width: 1023px) {
    .form-box {
        width: 60%;
    }

    .input-box {
        width: 100%;
        margin: 25px 0;
    }

    .input-box input {
        font-size: 1em;
    }

    .btnTheme {
        font-size: 1em;
    }
}
/* Media Query for Large Screens (1024px and above) */
@media (min-width: 1024px) {
    .form-box {
        width: 400px;
    }

    .input-box {
        width: 310px;
        margin: 30px 0;
    }

    .input-box input {
        font-size: 1em;
    }

    .btnTheme {
        font-size: 1em;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .form-box {
        width: 90%;
    }

    .input-box {
        width: 100%;
        margin: 20px 0;
    }

    .input-box input {
        font-size: 0.9em;
    }

    .btnTheme {
        font-size: 0.9em;
    }


}

@media (max-width: 450px)  {
    .form-box {
        width: 90%;
    }

    .input-box {
        width: 100%;
        margin: 20px 0;
    }

    .input-box input {
        font-size: 0.9em;
    }

    .btnTheme {
        font-size: 0.9em;
    }

    .login{
        width: 90%;
    }
    
}

@media (max-width: 700px)  {
    .animetion{
        display: none ;
    }
}

