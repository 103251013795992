@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");


:root {
  --body-background: #e4e9f7;
  --body--color: #000;
  --link--color: navy;
  --card-header-color-rgb: 33, 37, 41;
  --card-background: #fff;
  --card-border: #f2f3f8;
  --card-color: #464457;
  --nav-background: #20336b;
  --btn-background-color: #20336b;
  --btn-background-color-hover: #00baf2;
  --btn-color: #fff;
  --btn-color-hover: #20336b;
  
}

* {
  transition: all 0.3s ease-in-out;
  font-family:  "Inter", sans-serif;
}

body {
  margin: 0;
  font-family:  "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Inter-font{
  font-family:  "Inter", sans-serif;
}

.card-header {
  background-color: white;
  border: 1px solid #303030;
  border-radius: 10px !important;
  color: #303030;
  font-weight: 550;
  font-family: "Inter", sans-serif;
}

.card-header span{
  font-size: 19px;
  color: #303030;
  font-weight: 550; /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  padding: 6px;
    display: inline-block;
}
.ag-cell-value {
  color: #303030 !important;
  font-size: 15px !important;
  margin: 0;
} 

.NavbarSideCardCss{
  width: 15%;
  box-shadow: -1px -1px 17px -1px rgba(0,0,0,0.57);
  border-radius:10px;
  margin: 2px;
}

.NavbarSideCardHeaderCss{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  box-shadow: 0px 25px 20px -20px rgba(0,0,0,0.45);
}

.NavbarSideCardFooterCss{
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  box-shadow: 0px -29px 32px -32px rgba(0,0,0,0.45);
  border-top:1px solid #d7d7d7 ;
  margin-bottom: 5px;
  opacity: 999;
}

.ContentSideCardCss{
  width: 85%;
  border-radius: 10px;
  margin: 2px;
  background-color: #ecf0f5;
}


.scrollable-content {
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-content::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  padding: 30px;
}

/* Customize scrollbar track */
.scrollable-content::-webkit-scrollbar-track {
  background: #e4e9f7; /* Change this to desired background color */
  border-radius: 3px;
  margin: 5px;
}

/* Customize scrollbar thumb */
.scrollable-content::-webkit-scrollbar-thumb {
  background: #040000; /* Change this to desired thumb color */
  border-radius: 3px;
}

/* Customize scrollbar thumb on hover */
.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #e6a100; /* Change this to desired thumb color on hover */
  border-radius: 3px;
}



.popover-header span{
  font-size: 15px;
  color: #303030;
  font-weight: 550; /* Default font size for p tag */
  font-family: "Inter", sans-serif;
}




.card-header .FullLogo {
  width: 150px;
}

.card-header .OnlyTLogo {
  width: 150px;
  display: none; /* Initially hide OnlyTLogo */
}

@media (max-width: 600px) {
  .card-header .FullLogo {
    display: none; /* Hide FullLogo on small screens */
  }
  .card-header .OnlyTLogo {
    width: 24px;
    display: block; /* Show OnlyTLogo on small screens */
  }
  .card-footer  span{
         display: none;
  }
}

/* Media query for screens smaller than 768px */
@media(min-width: 600px) and (max-width: 768px) {
  .card-header .FullLogo {
    width: 60px; /* Adjust width for smaller screens */
  }
  .card-header .OnlyTLogo{
    display: none;
  }
  .card-footer  span{
    display: none;
 }
}

/* Media query for screens between 768px and 992px */
@media (min-width: 769px) and (max-width: 992px) {
  .card-header .FullLogo {
    width: 70px; /* Adjust width for medium screens */
  }
  .card-header .OnlyTLogo{
    display: none;
  }

  .card-footer  span{
   font-size: 14px;
}
}

/* Media query for screens between 992px and 1200px */
@media (min-width: 993px) and (max-width: 1200px) {
  .card-header .FullLogo {
    width: 100px; /* Adjust width for large screens */
  }
  .card-header .OnlyTLogo{
    display: none;
  }
}

/* Media query for screens larger than 1200px */
@media (min-width: 1201px) {
  .card-header .FullLogo {
    width: 120px; /* Adjust width for extra-large screens */
  }
  .card-header .OnlyTLogo{
    display: none;
  }
}



.iconsFonts {
  font-size: 25px !important;
  vertical-align: middle !important;
  cursor: pointer;  
  font-weight: 900;
}

.deleteIcon:hover {
  color: red;
}

.editIcon:hover {
  color: green;
}

.ag-center-cols-viewport {
  min-height: 100px !important;
}



.ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  color: #303030;
  font-weight: 550; /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  margin: 0;

}

.ag-header{
  border-bottom: 1px solid #b1b1b1  !important;
  border-top: 1px solid #b1b1b1 !important;
  background-color: #fff !important;
  box-shadow: 0px 9px 9px -8px rgba(0,0,0,0.79) !important;
}
.ag-cell-value, .ag-group-value {
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  font-size: 15px;
  color: #303030;
  font-family: "Inter", sans-serif;
  margin: 0;
  text-align: start;
}

.ag-row{
  background-color: #ffffff !important;
 border-bottom: 1px solid #303030 !important;
 margin-top: 2px;
  
}



.ag-theme-quartz {
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-row-border-style: dashed;
  --ag-row-border-width: 5px;
  --ag-row-border-color: rgb(150, 150, 200);
}

.ag-root-wrapper{
  border: none !important;
}

/* .ag-theme-alpine {
  height: 500px; 
  overflow: auto;
} */




@media screen and (max-width: 600px) {
  .ag-theme-alpine {
    height: auto; /* Let the container height adjust based on content */
  }
}

.btn-tapti {
  font-size: 15px;
  padding: 0px 10px 0px 10px;
  color: #303030;
  font-weight: 550;
  font-family: "Inter", sans-serif;
  background-color: #20336b;
  color: #e4e9f7;
  box-shadow: 1px 4px 5px 0px rgba(48, 48, 48, 0.45);
  border-radius: 16px;
  border: 1px solid #303030;
}

.btn-tapti:hover {
  color: black;
  background-color: rgb(130, 194, 251);
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 1px 4px 5px 0px #20336b;
  font-weight: 700;

}

.card-body .withBorder {
  background-color: white;
  border: 1px solid #303030;
  border-radius: 10px !important;
  margin-top: 3px;
}

.btn-close-white {
  filter: none;
}

.modal-header .btn-close {
  opacity: 1;
  background-image: url("./assets/icons8-close-94.png");
  background-size: 25px; /* Set the size of the background image */
  background-repeat: no-repeat;
}

.modal-header{
  /* box-shadow: 0px 20px 20px -20px rgba(0,0,0,0.45); */
  margin: 2px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #20336b;
  margin-bottom: 2px;
}

.modal-body{
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 2px;
}

.modal-title   {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700; /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  margin: 0;
}

.modal-content{
  background-color:#ecf0f500;
  border: none !important;
}


.form-label{
  font-size: 17px;
  color: #303030;
  font-weight: 700; /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  margin: 0;
}
.input-group .form-control{
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8PX !important;
  
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000a8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(5px);
  z-index: 9;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 4px solid;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid;
  border-color: transparent transparent #20336b #20336b;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 70px;
  height: 70px;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.ck-editor__editable_inline{
  height: 300px;
}


.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  padding-top: 0px;
  padding: 0px 25px;
}


.ValidationErrorMsg {
  width: 100%;
  margin-top: -1rem;
  font-size: 0.875em;
  color: #dc3545;
}

.css-13cymwt-control {
  border-radius: 0px !important;
}
.borderRed  .css-13cymwt-control {
  border-color: #dc3545;
}

.selectStartIcon {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.selectEndIcon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.offcanvas.offcanvas-start.show {
  z-index: 1111111;
}

.offcanvas.offcanvas-start{
  width: 50%;
}
.offcanvas-header{
  margin: 2px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #20336b;
  margin-bottom: 2px;
}
.offcanvas-title{
  font-size: 18px;
  color: #ffffff;
  font-weight: 700; /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  margin: 0;
}

.offcanvas-header .btn-close {
  opacity: 1;
  background-image: url("./assets/icons8-close-94.png");
  background-size: 25px; /* Set the size of the background image */
  background-repeat: no-repeat;
}

.offcanvas-body{
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 2px;
}


.offcanvas{
  background-color: rgba(255, 255, 255, 0);
  border: none !important;

}

 .ck.ck-editor .CKborder {
  position: relative;
  border: 1px solid red !important;
}