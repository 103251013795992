.iconWithNameContainer {
    /* padding: 10px; */
  }

  .icon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .icon-item {
    text-align: center;
    width: 45%; /* Each icon item takes up 50% width */
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .icon-item span{
    font-size: 14px;
    color: #303030;
    font-weight: 550; /* Default font size for p tag */
    font-family: "Inter", sans-serif;
    margin: 0;
  }
  
  .icon-item img {
    font-size: 40px;
    color: #20336b;
    width: 50px; /* Default font size for svg */
  }
  
  @media (min-width: 1601px) {
    .icon-item {
      width: 50%; /* Reduce width for extra-large screens */
    }
  
    .icon-item p {
      font-size: 18px; /* Adjust font size for extra-large screens */
    }
  
    .icon-item img {
      font-size: 45px; /* Adjust font size for extra-large screens */
      width: 60px; /* Adjust font size for extra-large screens */
    }
  }
  
  @media (max-width: 500px) {
    .icon-item {
      width: 100%; /* Each icon item takes up 100% width on extra-small screens */
    }
  
    .icon-item span {
      font-size: 10px; /* Adjust font size for extra-small screens */
    }
  
    .icon-item img {
      font-size: 25px; /* Adjust font size for extra-small screens */
      width: 50px; /* Adjust font size for extra-small screens */
    }
  }
  
  @media (max-width: 576px) {
    .icon-item {
      width: 100%; /* Each icon item takes up 100% width on extra-small screens */
    }
  
    .icon-item span {
      font-size: 12px; /* Adjust font size for extra-small screens */
    }
  
    .icon-item img {
      font-size: 25px; /* Adjust font size for extra-small screens */
      width: 50px; /* Adjust font size for extra-small screens */
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .icon-item {
      width: 80%; /* Reduce width for small screens */
    }
  
    .icon-item span {
      font-size: 14px; /* Adjust font size for small screens */
    }
  
    .icon-item img {
      font-size: 35px; /* Adjust font size for small screens */
      width: 50px; /* Adjust font size for small screens */
    }
  }
  
  @media (min-width: 769px) and (max-width: 1200px) {
    .icon-item {
      width: 60%; /* Reduce width for medium screens */
    }
  
    .icon-item span {
      font-size: 16px; /* Adjust font size for medium screens */
    }
  
    .icon-item img {
      font-size: 40px; /* Adjust font size for medium screens */
      width: 40px; /* Adjust font size for medium screens */
    }
  }
  
  
  .element2{
    background-color: rgb(255, 255, 255);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px -0px 6px 1px rgba(0, 0, 230, 0.3) inset;
    width: 100%;
   
  }
  
  .element:hover {
    background-color: rgb(255, 255, 255);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px -0px 6px 1px rgba(0, 0, 230, 0.3) inset;
  }