.activeDashBoard{
    color: black;
  background-color: rgb(130, 194, 251);
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 1px 4px 5px 0px #20336b;
  font-weight: 700;
}

.cardOfDashBoard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  padding: 2px;
  border-radius: 24px;
  overflow: hidden;
  line-height: 1.6;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content  {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 22px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.cardOfDashBoard .heading {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.3;
  z-index: 1;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.cardOfDashBoard .para {
  z-index: 1;
  opacity: 0.8;
  font-size: 18px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.cardOfDashBoard::before {
  content: "";
  position: absolute;
  height: 160%;
  border-radius: inherit;
  background: #0a3cff;
  background: linear-gradient(to right, #0a3cff, #0a3cff);
  transform-origin: center;
  animation: moving 10s linear infinite paused;
  transition: all 0.88s cubic-bezier(0.23, 1, 0.32, 1);

}

.cardOfDashBoard::before {
  animation-play-state: running;
  z-index: -1;
  width: 75%;
}

.cardOfDashBoard .content .heading,
.cardOfDashBoard .content .para {
  color: #000000;
}

.cardOfDashBoard{
  box-shadow: 0rem 6px 13px rgba(10, 60, 255, 0.1),
    0rem 24px 24px rgba(10, 60, 255, 0.09),
    0rem 55px 33px rgba(10, 60, 255, 0.05),
    0rem 97px 39px rgba(10, 60, 255, 0.01), 0rem 152px 43px rgba(10, 60, 255, 0);
  scale: 1.05;
  color: #000000;
}

@media (max-width: 450px) {
  .cardOfDashBoard{
    width: 28 0px;
  }
}

@keyframes moving {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
